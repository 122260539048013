<template>
  <CRow class="monthly-summary-table">
    <CCol>
      <RDataTable :fields="columns" :items="tableData">
        <template #company_name="{ item }">
          <td>
            <CLink :href="getCompanyLink(item)" target="_blank">
              {{ item.company_name }}
            </CLink>
          </td>
        </template>
        <template #balanced="{ item }">
          <td>
            <CIcon
              v-if="item.dollars_active === item.dollars_zoho && item.active_count === item.zoho_count"
              name="md-check-circle"
              height="24"
              class="color-success"
            />
            <CIcon
              v-else
              name="md-cancel"
              height="24"
              class="color-danger"
            />
          </td>
        </template>
        <template #boarded="{ item }">
          <td>
            {{ item.onboarding_count }} / <span v-if="item.offboarding_count > 0">-</span>{{ item.offboarding_count }}
          </td>
        </template>
        <template #item.onboarding_offboarding_difference="{ item }">
          <td>
            <span v-if="item.onboarding_offboarding_difference > 0">+</span>{{ item.onboarding_offboarding_difference }}
          </td>
        </template>
        <template #anticipated_revenue="{ item }">
          <td>
            <span>{{ formatCurrency( item.anticipated_revenue ) }}</span>
            <span v-if="item.currency_code === 'CAD'" class="color-cad"> CAD</span>
          </td>
        </template>
        <template #dollars_onboarding="{ item }">
          <td>
            <span>{{ formatCurrency( item.dollars_onboarding ) }}</span>
            <span v-if="item.currency_code === 'CAD'" class="color-cad"> CAD</span>
          </td>
        </template>
        <template #dollars_offboarding="{ item }">
          <td>
            <span>{{ formatCurrency( item.dollars_offboarding ) }}</span>
            <span v-if="item.currency_code === 'CAD'" class="color-cad"> CAD</span>
          </td>
        </template>
        <template #dollars_active="{ item }">
          <td>
            <span>{{ formatCurrency( item.dollars_active ) }}</span>
            <span v-if="item.currency_code === 'CAD'" class="color-cad"> CAD</span>
          </td>
        </template>
        <template #dollars_zoho="{ item }">
          <td>
            <span v-if="item.dollars_zoho !== 0" :class="{ 'zoho-danger' : item.dollars_active !== item.dollars_zoho }">
              {{ formatCurrency( item.dollars_zoho ) }}
            </span>
          </td>
        </template>
        <template #zoho_count="{ item }">
          <td>
            <CLink :to="viewRecurringInvoices(item)">
              {{ item.zoho_count }}
            </CLink>
          </td>
        </template>
      </RDataTable>
    </CCol>
  </CRow>
</template>

<script>
import { RDataTable } from '@/components/tables'
import { formatCurrency } from '@/utils'

export default {
  components: {
    RDataTable
  },
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      columns: [
        {
          key: 'company_name',
          label: 'Company Name',
          _style: 'width: 17%'
        },
        {
          key: 'balanced',
          _style: 'width: 5%'
        },
        {
          key: 'active_count',
          label: 'Active',
          _style: 'width: 5%'
        },
        {
          key: 'boarded',
          label: 'On/Offboarding',
          _style: 'width: 10%'
        },
        {
          key: 'onboarding_offboarding_difference',
          label: '+ / -',
          _style: 'width: 5%'
        },
        {
          key: 'anticipated_revenue',
          _style: 'width: 12%'
        },
        {
          key: 'dollars_onboarding',
          label: '$ Onboarding',
          _style: 'width: 12%'
        },
        {
          key: 'dollars_offboarding',
          label: '$ Offboarding',
          _style: 'width: 12%'
        },
        {
          key: 'dollars_active',
          label: '$ Active',
          _style: 'width: 12%'
        },
        {
          key: 'dollars_zoho',
          label: '$ Zoho',
          _style: 'width: 12%'
        },
        {
          key: 'zoho_count',
          label: 'Zoho Count',
          _style: 'width: 10%'
        }
      ]
    }
  },
  methods: {
    /**
     * Generate the admin link for the company summary page
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getCompanyLink (row) {
      const adminUrl = process.env.VUE_APP_ADMIN_URL
      return `${adminUrl}companies/${row.id}/properties`
    },
    /**
     * Format a number as a dollar value, or an empty string.
     *
     * @param {Number} amount
     * @returns {String}
     */
    formatCurrency (amount) {
      return formatCurrency(amount)
    },
    /**
     * Generate the route location object for navigating to
     * the company recurring invoice breakdown
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    viewRecurringInvoices (row) {
      return {
        name: 'MonthlySummaryRecurringInvoiceDetails',
        params: { cid: row.id }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.monthly-summary-table {
  ::v-deep .r-data-table {
    th:not(:first-of-type), td:not(:first-of-type) {
      text-align: center;
    }

    td:nth-last-child(2) {
      border-left: 2px solid $gray-100;
    }

    th {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    td {
      padding: 1rem 1.5rem;

      .color-success {
        color: $success;
      }
      .color-danger {
        color: $danger;
      }
      .zoho-danger {
        background-color: $danger;
        color: $white;
        padding: 0.25rem 0.5rem;
        border-radius: 16px;
      }
      .color-cad {
        font-size: 0.75rem;
        color: $gray-300;
      }
    }
  }
}
</style>
