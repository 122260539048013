<template>
  <CRow class="monthly-summary-widgets">
    <CCol lg="12" xl="6">
      <CRow>
        <CCol>
          <ReportingWidget
            :title="widgetData.activeProperties"
            text="Active Properties"
            info-text="Total number of properties with Active status."
          />
        </CCol>
        <CCol>
          <ReportingWidget
            :title="widgetData.onboardingProperties"
            text="Onboarding Properties"
            info-text="Total number of properties with a start date after today."
          />
        </CCol>
        <CCol>
          <ReportingWidget
            :title="widgetData.offboardingProperties"
            text="Offboarding Properties"
            info-text="Total number of properties with an end date after today."
          />
        </CCol>
        <CCol>
          <ReportingWidget
            :title="widgetData.netPropertyChange"
            text="Net Property Change"
            info-text="Net property count change between onboarding and offboarding properties."
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol lg="12" xl="6">
      <CRow>
        <CCol>
          <ReportingWidget
            :title="widgetData.anticipatedRevenue"
            text="Anticipated Revenue"
            info-text="Total monthly revenue of Active and Onboarding properties."
          />
        </CCol>
        <CCol>
          <ReportingWidget
            :title="widgetData.activeMonthlyRevenue"
            text="Active Monthly Revenue"
            info-text="Total monthly revenue of all properties with Active status as of today."
          />
        </CCol>
        <CCol>
          <ReportingWidget
            :title="widgetData.onboardingRevenue"
            text="Onboarding Revenue"
            info-text="Total monthly revenue of all properties currently onboarding."
          />
        </CCol>
        <CCol>
          <ReportingWidget
            :title="widgetData.offboardingRevenue"
            text="Offboarding Revenue"
            info-text="Total monthly revenue of all properties currently offboarding."
          />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import { formatCurrency } from '@/utils'
import { ReportingWidget } from '@/views/reporting/_components'

export default {
  components: {
    ReportingWidget
  },
  props: {
    widgets: {
      type: Object,
      required: true
    }
  },
  computed: {
    widgetData () {
      return {
        activeProperties: `${this.widgets.active_properties}`,
        onboardingProperties: `${this.widgets.onboarding_properties}`,
        offboardingProperties: `${this.widgets.offboarding_properties}`,
        netPropertyChange: this.widgets.monthly_property_change > 0 ? `+${this.widgets.monthly_property_change}` : `${this.widgets.monthly_property_change}`,
        anticipatedRevenue: this.getWidgetCurrencyValue(this.widgets.anticipated_revenue),
        activeMonthlyRevenue: this.getWidgetCurrencyValue(this.widgets.active_monthly_revenue),
        onboardingRevenue: this.getWidgetCurrencyValue(this.widgets.onboarding_revenue),
        offboardingRevenue: this.getWidgetCurrencyValue(this.widgets.offboarding_revenue)
      }
    }
  },
  methods: {
    /**
     * Displays a number with no decimals in the USD locale format. If the number is not defined it will just
     * return '-' instead
     *
     * @param value: dollar value to display
     * @returns {String}
     */
    getWidgetCurrencyValue (value) {
      return formatCurrency(value, '-', {
        minimumFractionDigits: 0, maximumFractionDigits: 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.monthly-summary-widgets {
  ::v-deep .col {
    padding-left: 10px;
    padding-right: 10px;
    .simple-widget-reporting {
      .card-body {
        padding: 1.5rem 0.5rem;

        .header {
          font-size: 1.8rem;
        }
        .pt-2 {
          padding-top: 0 !important;

          .widget-content {
            font-size: 0.875rem;
            min-height: unset;
          }
        }
      }
    }
  }
}
</style>
