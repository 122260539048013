var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"monthly-summary-table"},[_c('CCol',[_c('RDataTable',{attrs:{"fields":_vm.columns,"items":_vm.tableData},scopedSlots:_vm._u([{key:"company_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"href":_vm.getCompanyLink(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.company_name)+" ")])],1)]}},{key:"balanced",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.dollars_active === item.dollars_zoho && item.active_count === item.zoho_count)?_c('CIcon',{staticClass:"color-success",attrs:{"name":"md-check-circle","height":"24"}}):_c('CIcon',{staticClass:"color-danger",attrs:{"name":"md-cancel","height":"24"}})],1)]}},{key:"boarded",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.onboarding_count)+" / "),(item.offboarding_count > 0)?_c('span',[_vm._v("-")]):_vm._e(),_vm._v(_vm._s(item.offboarding_count)+" ")])]}},{key:"item.onboarding_offboarding_difference",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.onboarding_offboarding_difference > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(item.onboarding_offboarding_difference)+" ")])]}},{key:"anticipated_revenue",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(_vm.formatCurrency( item.anticipated_revenue )))]),(item.currency_code === 'CAD')?_c('span',{staticClass:"color-cad"},[_vm._v(" CAD")]):_vm._e()])]}},{key:"dollars_onboarding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(_vm.formatCurrency( item.dollars_onboarding )))]),(item.currency_code === 'CAD')?_c('span',{staticClass:"color-cad"},[_vm._v(" CAD")]):_vm._e()])]}},{key:"dollars_offboarding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(_vm.formatCurrency( item.dollars_offboarding )))]),(item.currency_code === 'CAD')?_c('span',{staticClass:"color-cad"},[_vm._v(" CAD")]):_vm._e()])]}},{key:"dollars_active",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(_vm.formatCurrency( item.dollars_active )))]),(item.currency_code === 'CAD')?_c('span',{staticClass:"color-cad"},[_vm._v(" CAD")]):_vm._e()])]}},{key:"dollars_zoho",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.dollars_zoho !== 0)?_c('span',{class:{ 'zoho-danger' : item.dollars_active !== item.dollars_zoho }},[_vm._v(" "+_vm._s(_vm.formatCurrency( item.dollars_zoho ))+" ")]):_vm._e()])]}},{key:"zoho_count",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CLink',{attrs:{"to":_vm.viewRecurringInvoices(item)}},[_vm._v(" "+_vm._s(item.zoho_count)+" ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }