<template>
  <div>
    <CRow class="mb-2">
      <CCol>
        <h2>Monthly Summary Report</h2>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <div class="subheader">
          The following report shows point-in-time aggregated data for active, expected incoming, and expected outgoing revenue.
        </div>
      </CCol>
    </CRow>
    <CRow align-horizontal="end" class="mb-4">
      <CCol sm="auto" class="controls">
        <CButton shape="pill" @click="refresh">
          <CIcon name="md-refresh" />
          Refresh
        </CButton>
        <CButton class="ml-2" shape="pill" @click="showDataExportModal = true">
          <CIcon name="exporting" />
          Export
        </CButton>
      </CCol>
    </CRow>
    <RSpinnerOverlay
      :loading="loading"
      :opacity="0.8"
      :z-index="1028"
      :is-full-page="true"
    >
      <monthly-summary-widgets :widgets="widgets" />
      <monthly-summary-table :table-data="tableData" />
    </RSpinnerOverlay>
    <DataExportModal
      :show="showDataExportModal"
      :data="tableData"
      :file-name="$route.name"
      @close="showDataExportModal = false"
    />
  </div>
</template>

<script>
import { DataExportModal } from '@/components/modals'
import { RSpinnerOverlay } from '@/components/spinners'
import { ReportingAPI } from '@/services/api/resources'

import MonthlySummaryTable from './_components/MonthlySummaryTable'
import MonthlySummaryWidgets from './_components/MonthlySummaryWidgets'

export default {
  components: {
    DataExportModal,
    RSpinnerOverlay,
    MonthlySummaryTable,
    MonthlySummaryWidgets
  },
  data () {
    return {
      loading: false,
      showDataExportModal: false,
      widgets: {},
      tableData: []
    }
  },
  created () {
    this.fetchMonthlySummaryData()
  },
  methods: {
    /**
     * Fetch the monthly summary data
     */
    async fetchMonthlySummaryData () {
      this.loading = true
      const response = await ReportingAPI.monthlySummary.list()
      if (response) {
        this.widgets = response.data
        this.tableData = response.data.companies
      }
      this.loading = false
    },
    /**
     * Refresh button handler
     */
    refresh () {
      this.fetchMonthlySummaryData()
    }
  }
}
</script>

<style lang="scss" scoped>
.controls button:hover {
  background-color: rgba($secondary-25, 0.8);
  color: $gray-900;
}
.subheader {
  font-size: 1rem;
  color: $gray-800;
  padding-bottom: 2rem;
}
</style>
